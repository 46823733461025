var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "calculator-select" }, [
    _c(
      "div",
      { staticClass: "options tabs", attrs: { tabindex: "-1" } },
      _vm._l(_vm.options, function (option) {
        return _c(
          "button",
          {
            key: option.id,
            staticClass: "tab",
            class: { active: _vm.transactionType === option.id },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.selectTransactionType(option.id)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(option.label) + "\n    ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }