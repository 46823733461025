var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.selectedCurrency
        ? _c(
            "div",
            {
              staticClass: "amount-select",
              class: { "visibility-hidden": !_vm.quote },
            },
            [
              _c("div", { staticClass: "label" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.content.amountSelectLabel ||
                            "2. Select how much you would like"
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("Tooltip", {
                      attrs: { content: _vm.content.amountTooltip },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row" }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "inputs" }, [
                _c("div", { staticClass: "input", attrs: { tabindex: "-1" } }, [
                  _c("small", { staticClass: "currency-code" }, [_vm._v("£")]),
                  _vm._v(" "),
                  _c("input", {
                    attrs: {
                      maxlength: "4",
                      min: "0",
                      type: "number",
                      tabindex: "0",
                    },
                    domProps: { value: _vm.desiredSourceQuantity },
                    on: { keyup: _vm.onUpdateSource },
                  }),
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "input", attrs: { tabindex: "-1" } }, [
                  _c("small", {
                    staticClass: "currency-code",
                    domProps: {
                      innerHTML: _vm._s(_vm.selectedCurrency.symbol),
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "number", min: "0", tabindex: "0" },
                    domProps: { value: _vm.desiredTargetQuantity },
                    on: { keyup: _vm.onUpdateTarget },
                  }),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedCurrency
        ? _c("div", { staticClass: "amount-result" }, [
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "item" }, [
                _c("span", [
                  _vm._v(
                    "You'll pay " + _vm._s(_vm._f("price")(_vm.customerWillPay))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item" }, [
                _c("span", [
                  _vm._v(
                    " You'll get " +
                      _vm._s(
                        _vm._f("price")(
                          _vm.customerWillGet,
                          _vm.selectedCurrency.symbol
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("TieredMessage", { attrs: { onBoostClick: _vm.boost } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "separator" }, [_c("span", [_vm._v("=")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }