var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "calculator-container", attrs: { tabindex: "0" } },
    [
      _c(
        "div",
        { class: { loading: _vm.isLoading }, attrs: { id: "calculator" } },
        [
          !_vm.isLoading &&
          _vm.content.cromessage &&
          _vm.content.cromessage != ""
            ? _c("div", {
                staticClass: "cromessage",
                domProps: { innerHTML: _vm._s(_vm.content.cromessage) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "spinner" },
                [
                  _c("Spinner", {
                    attrs: { name: "circle", color: "var(--color-links)" },
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _vm.COMMERCE_IS_SELLING ? _c("calculator-select") : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "inner" },
                    [
                      !_vm.COMMERCE_IS_SELLING
                        ? _c("h5", { staticClass: "calc-title" }, [
                            _vm._v("\n          Buy Travel Money\n        "),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "column" },
                        [
                          _c(
                            "div",
                            { staticClass: "label row" },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.content.currencySelectLabel ||
                                      "1. Select your currency"
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("Tooltip", {
                                attrs: { content: _vm.content.currencyTooltip },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.selectedCurrency && _vm.allCurrencies.length
                            ? _c("CurrencySelect", {
                                attrs: {
                                  currencies: _vm.allCurrencies,
                                  currency: _vm.selectedCurrency,
                                },
                                on: {
                                  selected: function ($event) {
                                    return _vm.selectCurrency($event)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isSellTransaction
                        ? _c("SellTransactionAmountPicker")
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isBuyTransaction
                        ? _c("BuyTransactionAmountPicker")
                        : _vm._e(),
                      _vm._v(" "),
                      _c("exchange-rate"),
                      _vm._v(" "),
                      _vm.isSellTransaction ? _c("product-select") : _vm._e(),
                      _vm._v(" "),
                      _c("product-selection"),
                      _vm._v(" "),
                      _vm.showStoreFinder ? _c("store-finder") : _vm._e(),
                      _vm._v(" "),
                      _vm.showStoreModal ? _c("modal-store") : _vm._e(),
                      _vm._v(" "),
                      _vm.showAddToCartButton
                        ? _c("calculator-result")
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasCart ? _c("ReturnToCart") : _vm._e(),
                      _vm._v(" "),
                      _vm.optionalContent
                        ? _c("div", [
                            _c("div", {
                              staticClass: "optional-content",
                              domProps: {
                                innerHTML: _vm._s(_vm.optionalContent),
                              },
                            }),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
        ]
      ),
      _vm._v(" "),
      _vm.content && _vm.content.trustPilotScript && _vm.isPageStore
        ? _c("div", {
            staticClass: "trustpilot-banner",
            domProps: { innerHTML: _vm._s(_vm.content.trustPilotScript) },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }