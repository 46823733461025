<template>
  <div>
    <div class="review--item" :class="{ expand: expand }">
      <div class="review--content">
        <span>Currency</span>

        <div class="review--row">
          <div :class="`icon icon__${line.code.toLowerCase()}`"></div>

          <div class="review--currency">
            <div class="code">{{ line.code }}</div>
          </div>
        </div>
      </div>

      <div class="review--quantity">
        <span>Amount</span>

        <div class="input_container" tabindex="-1">
          <div class="Currency-Code">
            <small class="currency-code" v-html="line.symbol"></small>
          </div>
          <input
                 type="text"
                 @keyup="targetToSource($event.target.value)"
                 :value="target"
                 tabindex="0"
                 />
        </div>

        <div v-if="expand" class="review--quantity-displaySecond">
          You'll get {{ qty | price(line.symbol) }}
        </div>
      </div>

      <div class="review--content">
        <span>Rate</span>
        <strong v-if="rate">{{ rate | rate }}</strong>
      </div>

      <div class="review--quantity">
        <span>Cost</span>

        <div class="input" tabindex="-1">
          <small class="currency-code">£</small>
          <input
                 maxlength="4"
                 type="text"
                 @keyup="sourceToTarget($event.target.value)"
                 :value="source"
                 tabindex="0"
                 />
        </div>

        <div v-if="expand" class="review--quantity-display">
          You'll pay {{ price | price }}
        </div>
      </div>

      <div class="review--buttons">
        <button
                class="button button--small button--primary"
                name="addCurrency"
                id="addToCart"
                :disabled="target === 0"
                @click="addNewLine"
                >
          Add to cart
        </button>
        <button
                class="close"
                @click="$emit('remove', line.code)"
                name="removeCurrency"
                ></button>
      </div>
    </div>
    <tiered-message v-if="shoppingCart.product.id !== 3" :quantity="target" :CurrencyRate="currencyRate"  :line="line"  @boostRate="boostRate" />
    <div
         class="notification restriction"
         v-if="line.currencyRestrictions.length && shoppingCart.product.id !== 3"
         >
      <p v-for="(restriction, index) in line.currencyRestrictions" :key="index">
        {{ restriction.message }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { getRate } from '@vue/services/api/currency.api'
import { showInfo } from '@vue/services/toast.service'
import {GBP_MAX_VALUE } from '@vue/common/constants/config'
import TieredMessage from './checkout-tiered-message'
import {
  convertSourceToTarget,
  convertTargetToSource,
} from '@vue/services/currency.service'
import { nextMultiple, calculateTotalPrice } from '@vue/services/currency.service'


export default {
  name: 'ReviewItemNew',
    props: ['line'],
    components: {
      TieredMessage,
    },
  data() {
    return {
      source: 0,
      target: 0,
      currencyRate: {
        rate: null,
        price: null,
      },
      tieredRate: {
        rate: null,
        price: null,
      },
      GBP_MAX_VALUE
    }
  },
  computed: {
    ...mapState({
      storeId: (state) => state.store.defaultStore.id,
      shoppingCart: (state) => state.cart.shoppingCart,
    }),
    expand() {
      return (
        this.target > 0 && this.source > 0 && this.shoppingCart.product.id !== 3
      )
    },
    qty() {
      if (!this.target) {
        return 0
      }

      if (this.shoppingCart.product.id === 3) {
        return this.target
      } else {
        // account for bank note values, e.g. we don't sell 3 EUR
        return nextMultiple(this.line.minimumDenomination, this.target)
      }
    },
    rate() {
      return this.tieredRate.rate || this.currencyRate.rate
    },
    price() {
      if (!this.target) {
        return 0
      }

      return calculateTotalPrice(
        this.qty,
        this.tieredRate.price || this.currencyRate.price
      )
    },
  },
  watch: {
    target(value) {   
      // Loop through tiered rates and find the matching rate for the target quantity
      const tieredRate = this.currencyRate.rateTiers.find(
        (tier) => value >= tier.amount
      )

      this.tieredRate = tieredRate || { rate: null, price: null }
    },
    rate() {
      if (this.target > 0 && this.source > 0) this.targetToSource(this.target)
    },
  },
  methods: {
    ...mapActions({
      addLine: 'cart/addLine',
    }),
    boostRate(Amount) {
      this.source = Amount.source;
      this.target = Amount.target;
    },
    addNewLine() {
      this.addLine({
        currencyCode: this.line.code,
        qty: this.target,
      }).then(() => {
        showInfo({
          text:
            this.shoppingCart.product.id === 3
              ? `£${this.source} added to your cart!`
              : `${this.line.symbol}${this.qty} added to your cart!`,
        })
      })
      this.$emit('remove', this.line.code)
    },
    sourceToTarget(quantity) {
      if (quantity > GBP_MAX_VALUE) {
        quantity = GBP_MAX_VALUE
      }

      const { source, target } = convertSourceToTarget(
        quantity,
        this.tieredRate.rate || this.currencyRate.rate
      )

      this.source = parseInt(source)
      this.target = parseInt(target)
    },
    targetToSource(quantity) {
      const { source, target } = convertTargetToSource(
        quantity,
        this.tieredRate.rate || this.currencyRate.rate
      )

      this.source = parseInt(source)
      this.target = parseInt(target)
    },
  },
  mounted() {
    // Set affiliate code
    const affiliateCode = sessionStorage.getItem('affiliateCode')
      ? sessionStorage.getItem('affiliateCode')
      : null

    getRate({
      storeId: this.storeId,
      productId: this.shoppingCart.product.id,
      currency: this.line.code,
      affiliateCode: affiliateCode,
    }).then(({ data }) => {
      this.currencyRate = data
    })
  },
}
</script>
