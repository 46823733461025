import { getCurrencies } from '@vue/services/api/currency.api'
import { setDefaultCurrency } from '@vue/services/currency.service'
import { getParameterByName } from '@vue/services/calculator.service'

const state = {
  currencies: []
}

const mutationTypes = {
  SET_CURRENCIES: 'SET_CURRENCIES'
};

const actions = {
  getCurrencies({ commit, dispatch }) {
    getCurrencies().then(({ data }) => {
      commit(mutationTypes.SET_CURRENCIES, data)
      let queryCurrency = getParameterByName('currency')

      let bc = localStorage.getItem("selcurr");
      if (bc !== null) {
        queryCurrency = bc;
        localStorage.removeItem("selcurr");
      }
      queryCurrency === null
        ? commit("calculator/SET_CURRENCY", setDefaultCurrency(data), { root: true } )
        : commit("calculator/SET_CURRENCY",
                  data.find(x => x.code.toUpperCase() === queryCurrency.toUpperCase()),
                  { root: true } 
                );
      dispatch("calculator/updateQuote", null, { root: true } )
    })
  }
}

const mutations = {
  [mutationTypes.SET_CURRENCIES](state, currencies) {
    state.currencies = currencies
  }
}

const currency = {
  namespaced: true,
  state,
  actions,
  mutations
}

export default currency
