<template>
  <div>
    <div
      class="payment"
      :class="{ 'payment-bordered': isSellTransaction }"
    >
      <div v-if="isIframe" class="iframe">
        <a :href="cartUrl" target="_blank" class="button button--checkout">
          Add to cart
        </a>
      </div>
      <div v-else>
        <button
          class="button button--primary"
          @click="addToCart"
          id="addToCart"
          :disabled="loading"
        >
          <span v-if="isSellTransaction">Add to cart</span>
          <span v-else-if="isBuyTransaction">Sell back now</span>
          <span v-else>Select a branch</span>
        </button>
      </div>

      <div class="spinner" v-if="loading">
        <Spinner name="circle" color="var(--color-links)" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { SITE_ID } from '@vue/common/constants/config'
import { createCart, addLine } from '@vue/services/api/cart.api'
import { saveToken } from '@vue/services/jwt.service'
import { TransactionTypes } from '@vue/common/constants/transactionTypes'

import Spinner from 'vue-spinkit'

export default {
  name: 'CalculatorResult',
  components: {
    Spinner,
  },
  data() {
    return {
      loading: false,
      domain: this.$root.origin,
      isIframe: this.$root.isIframe,
    }
  },
  computed: {
    ...mapGetters({
      isSellTransaction: 'calculator/isSellTransaction',
      isBuyTransaction: 'calculator/isBuyTransaction',
    }),
    ...mapState({
      selectedProduct: (state) => state.calculator.selectedProduct,
      store: (state) => state.store.store,
      shoppingCart: (state) => state.cart.shoppingCart,
      selectedCurrency: (state) => state.calculator.selectedCurrency,
      quantity: (state) => state.calculator.quantity,
      content: (state) => state.calculator.content
    }),
    iframeQuery() {
      const query = {
        currency: this.selectedCurrency.code,
        amount: this.quantity,
        product_id: this.selectedProduct.id
      }

      if (this.store.id !== 1) {
        query.store_id = this.store.id
      }

      return new URLSearchParams(query).toString()
    },
    cartUrl() {
      return this.selectedProduct.id === 3
        ? `${this.domain}/selling/view-cart?${this.iframeQuery}`
        : `${this.domain}/checkout/view-cart?${this.iframeQuery}`
    },
  },
  methods: {

    addToCart() {
      const affiliateCode = sessionStorage.getItem('affiliateCode')
        ? sessionStorage.getItem('affiliateCode')
        : null

      this.loading = true;

      if (!this.shoppingCart) {
        createCart({
          siteId: SITE_ID,
          storeId: this.store.id,
          productId: this.selectedProduct.id,
          affiliateCode: affiliateCode,
        }).then(({ data }) => {
          saveToken(data.token)
          addLine({
            currencyCode: this.selectedCurrency.code,
            qty: this.quantity,
          }).then(() => {
            const uri = this.selectedProduct.transactionType === TransactionTypes.SELL
              ? '/checkout/view-cart'
              : '/selling/view-cart';
            window.location.href = uri;
          })
        }).catch((e) => {
          this.loading = false;
        });
      } 
    },
  },
}
</script>
