import Vue from 'vue'
import store from '@vue/store/index'

import Steps from './steps'
import CheckoutHeader from './header'

if (document.getElementById('checkout-banner__steps') != null) {
  new Vue({
    el: '#checkout-banner__steps',
    render: createElement => createElement(Steps),
    store
  });
}

if (document.getElementById('checkout-header') != null) {
  new Vue({
    el: '#checkout-header',
    name: 'HeaderApp',
    render: createElement => createElement(CheckoutHeader),
    store
  });
}
