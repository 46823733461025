var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.products && _vm.selectedProduct
    ? _c("div", { staticClass: "column product-select" }, [
        _c(
          "div",
          { staticClass: "label row" },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.content.productSelectLabel ||
                    "3. Select how you would like to receive your currency"
                )
              ),
            ]),
            _vm._v(" "),
            _c("Tooltip", {
              attrs: { content: _vm.content.deliveryOptionsTooltip },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "options", attrs: { tabindex: "-1" } },
          _vm._l(_vm.filteredProducts, function (product) {
            return _c("div", { key: product.id, staticClass: "radio" }, [
              _c("input", {
                attrs: {
                  type: "radio",
                  name: "products",
                  id: _vm.kebabCase(product.name),
                  disabled: _vm.shoppingCart,
                  tabindex: "0",
                },
                domProps: { checked: product.id === _vm.selectedProduct.id },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.selectProduct(product.id)
                  },
                  click: function ($event) {
                    return _vm.selectProduct(product.id)
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: _vm.kebabCase(product.name) } }, [
                _vm._v("\n        " + _vm._s(product.name) + "\n      "),
              ]),
            ])
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }