var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "payment",
        class: { "payment-bordered": _vm.isSellTransaction },
      },
      [
        _vm.isIframe
          ? _c("div", { staticClass: "iframe" }, [
              _c(
                "a",
                {
                  staticClass: "button button--checkout",
                  attrs: { href: _vm.cartUrl, target: "_blank" },
                },
                [_vm._v("\n        Add to cart\n      ")]
              ),
            ])
          : _c("div", [
              _c(
                "button",
                {
                  staticClass: "button button--primary",
                  attrs: { id: "addToCart", disabled: _vm.loading },
                  on: { click: _vm.addToCart },
                },
                [
                  _vm.isSellTransaction
                    ? _c("span", [_vm._v("Add to cart")])
                    : _vm.isBuyTransaction
                    ? _c("span", [_vm._v("Sell back now")])
                    : _c("span", [_vm._v("Select a branch")]),
                ]
              ),
            ]),
        _vm._v(" "),
        _vm.loading
          ? _c(
              "div",
              { staticClass: "spinner" },
              [
                _c("Spinner", {
                  attrs: { name: "circle", color: "var(--color-links)" },
                }),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }