<template>
  <div
       class="column product-select"
       v-if="products && selectedProduct"
       >
    <div class="label row">
      <span>{{
        content.productSelectLabel ||
        '3. Select how you would like to receive your currency'
      }}</span>
      <Tooltip :content="content.deliveryOptionsTooltip" />
    </div>

    <div class="options" tabindex="-1">
      <div
        class="radio"
           v-for="product in filteredProducts"
          :key="product.id"
      >
        <input
          type="radio"
               name="products"
               :id="kebabCase(product.name)"
               :disabled="shoppingCart"
               :checked="product.id === selectedProduct.id"
               tabindex="0"
               @keyup.enter="selectProduct(product.id)"
          @click="selectProduct(product.id)"
        />
        <label :for="kebabCase(product.name)">
          {{ product.name }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import Tooltip from '@vue/common/components/Tooltip'

  export default {
    name: 'ProductSelect',
    components: {
      Tooltip,
    },
    computed: {
      ...mapState({
        content: (state) => state.calculator.content,
        products: (state) => state.product.products,
        selectedProduct: (state) => state.calculator.selectedProduct,
        shoppingCart: (state) => state.cart.shoppingCart,
        store: (state) => state.store.store,
        transactionType: (state) => state.calculator.transactionType,
      }),
      filteredProducts() {
        return this.products.filter(p => p.transactionType === this.transactionType).reverse();
      }
    },
    methods: {
      ...mapActions({
        selectProduct: 'calculator/selectProduct'
      }),
      kebabCase: function(name) {
        const re = /([0-9]+|([A-Z][a-z]+)|[a-z]+|([A-Z]+)(?![a-z]))/g

        const toKebabCase = str =>
          (String(str ?? '').match(re) || []).map(x => x.toLowerCase()).join('-')

        return toKebabCase(name)
      }
    },
  }
</script>
