var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "review--item" }, [
        _c("div", { staticClass: "review--content" }, [
          _c("span", [_vm._v("Currency")]),
          _vm._v(" "),
          _c("div", { staticClass: "review--row" }, [
            _c("div", {
              class: "icon icon__" + _vm.line.currency.code.toLowerCase(),
            }),
            _vm._v(" "),
            _c("div", { staticClass: "review--currency" }, [
              _c("div", { staticClass: "code" }, [
                _vm._v(_vm._s(_vm.line.currency.code)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "label" }, [
                _vm._v("(" + _vm._s(_vm.line.currency.pluralName) + ")"),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "review--quantity" }, [
          _c("span", [_vm._v("Amount")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "input_container", attrs: { tabindex: "-1" } },
            [
              _c("div", { staticClass: "Currency-Code" }, [
                _c("small", {
                  staticClass: "currency-code",
                  domProps: { innerHTML: _vm._s(_vm.line.currency.symbol) },
                }),
              ]),
              _vm._v(" "),
              _c("quantity", { attrs: { value: _vm.line } }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "review--content" }, [
          _c("span", [_vm._v("Rate")]),
          _vm._v(" "),
          _c("strong", [_vm._v(_vm._s(_vm._f("rate")(_vm.line.rate)))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "review--content" }, [
          _vm.shoppingCart.product.id !== 3
            ? _c("span", [_vm._v("Cost")])
            : _c("span", [_vm._v("You will receive")]),
          _vm._v(" "),
          _c("strong", [_vm._v(_vm._s(_vm._f("price")(_vm.line.total)))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "review--delete" }, [
          _c("button", {
            staticClass: "close",
            on: {
              click: function ($event) {
                return _vm.deleteLine(_vm.line.id)
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _vm.shoppingCart.product.id !== 3
        ? _c("tiered-message", {
            attrs: {
              quantity: _vm.line.qty,
              CurrencyRate: _vm.currencyRate,
              line: _vm.line.currency,
            },
            on: { boostRate: _vm.boostRate },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.restrictions.length && _vm.shoppingCart.product.id !== 3
        ? _c(
            "div",
            { staticClass: "notification restriction" },
            _vm._l(_vm.restrictions, function (restriction, index) {
              return _c("p", { key: index }, [
                _vm._v("\n      " + _vm._s(restriction.message) + "\n    "),
              ])
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }