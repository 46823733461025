import {
  getCart,
  recreateCart,
  addLine,
  isBuyBack,
  isRefund,
  updateLine,
  deleteLine,
  recreatewebcart,
  getsitesettings,
} from '@vue/services/api/cart.api'
import { saveToken, deleteBearerToken, hasBearerToken } from '@vue/services/jwt.service'
import { actionTypes } from '@vue/common/constants/store.types'

const initialState = {
  shoppingCart: null,
  showCart: false,
  loading: false,
}

const state = { ...initialState }

const actions = {
  async init({ dispatch, state }) {
    if (hasBearerToken()) {
      await dispatch('getCart')
    }
  },
  async getCart({ commit }) {
    const response =  await getCart()
    commit('setCart', response.data);
  },
  recreateCart({ commit }) {
    recreateCart().then(({ data }) => {
      saveToken(data.token)
      getCart().then(({ data }) => {
        commit('setCart', data)
      })       
      if (window.location.pathname.includes('checkout')) 
        window.location = '/checkout/view-cart'        
      if (window.location.pathname.includes('selling')) 
        window.location = '/selling/view-cart'
    })
  },
  recreatewebcart({ commit }) {       
    recreatewebcart(id).then(({ data }) => {
      saveToken(data.token)
      getCart().then(({ data }) => {
        commit('setCart', data)
      }) 
    })
  }, 
   getsitesettings({ commit }, id) {        
     getsitesettings(id).then(({ data }) => {
       commit('siteSettings',data) 
     })
   },
  [actionTypes.AddLine]({ commit }, line) {
    return addLine({
      currencyCode: line.currencyCode,
      qty: line.qty,
    }).then(({ data }) => {
      commit('setCart', data)
    })
  },  
  [actionTypes.IsBuyBack]({ commit }, buyback) {
    isBuyBack(buyback).then(({ data }) => {
      commit('setCart', data)
    })
  },
  [actionTypes.IsRefund]({ commit }, refund) {
    isRefund(refund).then(({ data }) => {
      commit('setCart', data)
    })
  },
  [actionTypes.UpdateLine]({ commit }, params) {
    updateLine(params.id, {
      currencyCode: params.code,
      qty: params.quantity,
    }).then(({ data }) => {
      commit('setCart', data)
    })
  },
  [actionTypes.DeleteLine]({ commit }, id) {
    deleteLine(id).then(({ data }) => {
      commit('setCart', data)
    })
  },
  abandonCart({ commit }) {
    commit('clearCart')
    deleteBearerToken()
  },
}

const mutations = {
  loadingCart(state, flag) {
    state.loading = flag
  },
  setCart(state, cart) {
    state.shoppingCart = cart
  },  
  clearCart(state) {
    state.shoppingCart = null
  },
  toggleCart(state, flag) {
    state.showCart = flag
    document.dispatchEvent(new Event('cartToggled'));
  },
}

const getters = {
  hasCart: (state) => !!state.shoppingCart
}; 

const cart = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

export default cart
