export const SITE_ID = window.commerceSettings.siteId

export const SITE_NAME = window.commerceSettings && window.commerceSettings.siteName
export const COMMERCE_API_URL = window.commerceSettings && window.commerceSettings.apiBaseUrl || ''
export const COMMERCE_IS_SELLING = window.commerceSettings && window.commerceSettings.enableSelling
export const COMMERCE_SHOW_MARKETING = window.commerceSettings && window.commerceSettings.showMarketingPreference
export const COMMERCE_SHOW_ABTA = window.commerceSettings && window.commerceSettings.showAbtaPreference
export const DEFAULT_GBP = window.commerceSettings && window.commerceSettings.DEFAULT_GBP
export const GBP_MAX_VALUE = window.commerceSettings && window.commerceSettings.GBP_MAX_VALUE
export const DEFAULT_FC = window.commerceSettings && window.commerceSettings.DEFAULT_FC

export const COMMERCE_SHOW_BUYBACK = window.commerceSettings && window.commerceSettings.showBuybackGuarantee

export const COMMERCE_SHOW_REFUND = window.commerceSettings && window.commerceSettings.showRefundGuarantee

export const GEOCODING_URL = 'https://api.addressy.com/Geocoding/International/Geocode/v1.10/json3.ws'
export const GEOCODING_KEY = 'TC64-JA49-CW97-FH68'

