<template>
  <div
    class="amount-select"
    v-if="selectedCurrency && selectedProduct"
    :class="{ 'visibility-hidden': !quote }">
    <div class="label">
      <div class="selling row">
        <span>{{
          content.amountSelectSellLabel || '2. Amount to Sell Back'
        }}</span>
        <div class="row">
          <span>You will receive</span>
          <Tooltip :content="content.sellingTooltip" />
        </div>
      </div>
      <div class="row"></div>
    </div>
    <div class="inputs">
      <div class="input" tabindex="-1">
        <small class="currency-code" v-html="selectedCurrency.symbol"></small>
        <input
          type="number"
          min="0"
          @keyup="onUpdateSource"
          :value="desiredSourceQuantity"
          tabindex="0"
        />
      </div>
        <div class="row">
          <div class="icon icon__gbp"></div>
          <span class="currency-code">£</span>
          <span class="sell">{{ desiredTargetQuantity.toFixed(2) }}</span>
        </div>
    </div>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js'
import { mapState, mapActions } from 'vuex'
import { DEFAULT_FC } from '@vue/common/constants/config'

import Tooltip from '@vue/common/components/Tooltip'

export default {
  name: 'AmountSelectSell',
  components: {
    Tooltip,
  },
  data() {
    return {
      debounceTimer: null,
      lastHashCode: null,
      DEFAULT_FC,
      desiredSourceQuantity: 0,
      desiredTargetQuantity: 0
    }
  },
  computed: {
    ...mapState({
      content: (state) => state.calculator.content,
      quote: (state) => state.calculator.quote,
      selectedCurrency: (state) => state.calculator.selectedCurrency,
      selectedProduct: (s) => s.calculator.selectedProduct,
    }),
  },
  watch: {
    'quote': {
      handler(value) {
        if(this.lastHashCode == value.hashCode) {
          return; // When a store is picked the rates are reloaded from the new store (but rates don't change per store anymore).
        }
        this.lastHashCode = value.hashCode;
        this.updateSource(DEFAULT_FC);
      },
      immediate: true,
    }
  },
  methods: {
    ...mapActions({
      setQuantity: 'calculator/setQuantity',
    }),
    onUpdateSource(event){
      if(event.key == 'Tab') {
        return;
      }

      this.resetTimer();

      this.debounceTimer = setTimeout(() => {
        this.resetTimer();
        this.updateSource(Number(event.target.value) || 0);
      }, 500)
    },
    canUpdate() {
      // This component shouldn't ever receive rates for other products, but right now it does so handle it until refactor.
      return this.quote.rate && this.selectedProduct?.id == 3;
    },
    updateSource(quantity) {
      if(!this.canUpdate()){
        this.reset();
        return;
      }
      const sourceQuantity = BigNumber(quantity) // FC
        .dp(2, BigNumber.ROUND_HALF_EVEN)
        .toNumber();

      const targetQuantity = BigNumber(quantity) // GBP
        .div(this.quote.rate)
        .dp(2, BigNumber.ROUND_HALF_EVEN)
        .toNumber();

      this.desiredSourceQuantity = sourceQuantity;
      this.desiredTargetQuantity = targetQuantity;

      this.setQuantity(Math.floor(sourceQuantity))
    },
    reset() {
      this.desiredSourceQuantity = 0;
      this.desiredTargetQuantity = 0;

      this.setQuantity(0);
    },
    resetTimer(){
      clearTimeout(this.debounceTimer);
      this.debounceTimer = null;
    }
  },
}
</script>
