<template>
  <div>
    <div
      class="payment"
      :class="{ 'payment-bordered': showBorder }">
      <div>
        <button
          class="button button--checkout"
          @click="redirectToCheckout"
          :disabled="disabled"
        >
          Review &amp; checkout
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { TransactionTypes } from '@vue/common/constants/transactionTypes'

export default {
  name: 'ReturnToCart',
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState({
      shoppingCart: (state) => state.cart.shoppingCart
    }),
    showBorder() {
      return this.shoppingCart.product.transactionType === TransactionTypes.SELL
    }
  },
  methods: {
    redirectToCheckout() {
      this.loading = true;
      const uri =  this.shoppingCart.product.transactionType === TransactionTypes.SELL
          ? '/checkout/view-cart'
          : '/selling/view-cart';
      window.location.href = uri
    },
  },
}
</script>
