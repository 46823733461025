<template>
  <div class="tiered-message" > 
    <p v-if="isNextTier(nextTier)">
      Rate fully boosted! You're receiving our Best Rate
    </p>
    <p v-else-if="isNextTier(nextTier) == null">
    </p>
    <p v-else>
      Buy
      <strong>
        {{ nextTier.amount | price(selectedCurrency.symbol) }}
        {{ selectedCurrency.shortName }}
      </strong>
      or more to
      <button @click="onBoostClick(nextTier.amount)" class="boost">boost your rate</button> and
      receive our improved rate
    </p>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import BigNumber from 'bignumber.js'
export default {
  name: 'TieredMessage',
  props: {
    onBoostClick: Function
  },
  data() {
    return {
      amount: null,
      rate: null
    }
  },
  computed: {
    ...mapState({
      quote: state => state.calculator.quote,
      selectedCurrency: state => state.calculator.selectedCurrency,
      quantity: state => state.calculator.quantity
    }),
    ...mapGetters({
      tieredRate: 'calculator/tieredRate',
      nextTier: 'calculator/nextTier',
    }),
    nextTierAmount() {
      return Math.floor(this.nextTier.amount);
    }
  },
  methods: {
    ...mapActions({
      setQuantity: 'calculator/setQuantity',
    }),
    isNextTier(nextTier) {
      if (nextTier == null) {
        if(this.quote.rateTiers && this.quote.rateTiers.length < 1) {
          return null
        } else {
          return true
        }
      }
      if (nextTier != null) {
        return false
      }
    }
  }
}
</script>
