<template>
  <div class="checkout">
    <div class="container">
      <div class="view-cart">
        <Spinner
          name="circle"
          color="var(--color-links)"
          v-if="cartLoading && !cartEmpty"
        />

        <div class="checkout--empty" v-if="cartEmpty && !cartLoading">
          <h3>Your cart is empty, add some currency to continue</h3>

          <a class="button button--primary" href="/"> Leave checkout </a>
        </div>

        <div class="checkout--review">
          <!-- :class="{ 'col-9': shoppingCart.product.id === 2 }" -->
          <section class="review" v-if="!cartEmpty && !cartLoading">
            <div class="review--header">
              <span>Currency</span>
              <span>Amount</span>
              <span>Rate</span>
              <span v-if="shoppingCart.product.id !== 3">Cost</span>
              <span v-else>You will receive</span>
              <span class="review--delete"></span>
            </div>

            <ReviewItem
              v-for="line in shoppingCart.lines"
             :key="line.currency.code"
              :line="line"              
            />
          </section>

          <section
            class="review review--add"
            v-if="filteredCurrencies.length > 0"
          >
            <h4>Add new currency</h4>

            <CurrencySelect
              :currencies="filteredCurrencies"
              @selected="selectCurrency($event)"
            />

            <div class="review--header" v-if="selected.length">
              <span>Currency</span>
              <span>Amount</span>
              <span>Rate</span>
              <span v-if="shoppingCart.product.id !== 3">Cost</span>
              <span v-else>You will receive</span>
              <span class="review--delete"></span>
            </div>

            <ReviewItemNew
              v-for="line in selected"
              :key="line.code"
              :line="line"
              @remove="removeCurrency($event)"
            />
          </section>
          <div class="form" v-if="!cartEmpty && !cartLoading">
            <form
              v-if="COMMERCE_SHOW_BUYBACK || COMMERCE_SHOW_REFUND"
              @submit.prevent="onSubmit"
              autocomplete="off"
              novalidate
            >
              <!-- COMMERCE BUYBACK -->
              <div
                v-if="COMMERCE_SHOW_BUYBACK && shoppingCart.product.id !== 3"
                class="checkout-inputs"
              >
                <h4>Would you like to add a Buyback Guarantee?</h4>
                
                 <p v-if="IsPayPoint">
                  Return up to 30% of your travel money at exactly the same
                  exchange rate, up to 30 days after your purchase date. On
                  average our customers who use our Buyback Guarantee are £20
                  better off. Please note currency can only be returned to a eurochange branch.
                </p>                
                <p v-else>
                  Return up to 30% of your travel money at exactly the same
                  exchange rate, up to 30 days after your purchase date. On
                  average our customers who use our Buyback Guarantee are £20
                  better off
                </p>

                <div
                  class="notification warning"
                  v-if="shoppingCart.total < 150"
                >
                  <p>
                    Your cart is too small to use the Buyback Guarantee. Please
                    add more currency to your cart. Our minimum spend is £{{IsPayPoint?200:150}}.
                  </p>
                </div>

                <div class="input" :class="{ validator: $v.buyback.$error }">
                  <div class="radio">
                    <input
                      type="radio"
                      name="buyback"
                      id="buyback-agree"
                      tabindex="0"
                      v-model="buyback"
                      :value="true"
                      :disabled="shoppingCart.total < 150"
                    />
                    <label for="buyback-agree">
                      I'm likely to return home with unused travel money -
                      please add the Buyback Guarantee to my order for £4
                    </label>
                  </div>

                  <div class="radio">
                    <input
                      type="radio"
                      name="buyback"
                      id="buyback-decline"
                      tabindex="0"
                      :value="false"
                      v-model="buyback"
                      :disabled="shoppingCart.total < 150"
                    />
                    <label for="buyback-decline">
                      I'm certain I won't have any travel money left on my
                      return - do not add the Buyback Guarantee
                    </label>
                  </div>

                  <span class="error" v-if="!$v.buyback.required">
                    Please select your Buyback guarantee option
                  </span>
                </div>
              </div>

              <!-- COMMERCE REFUND -->
              <div
                v-if="COMMERCE_SHOW_REFUND && shoppingCart.product.id !== 3"
                class="checkout-inputs"
              >
                <h4>Would you like to add a Refund Guarantee?</h4>

                 <p>
                  In the event that your holiday is cancelled by your travel
                  provider, you can return your order in its entirety, up to 30
                  days after your purchase date, for a full refund.
                  <a target="_blank" href="/company/legal/terms">Terms and conditions</a> apply.
                </p>

                <div class="input" :class="{ validator: $v.refund.$error }">
                  <div class="radio">
                    <input
                      type="radio"
                      name="refund"
                      id="refund-agree"
                      tabindex="0"
                      v-model="refund"
                      :value="true"
                    />
                    <label for="refund-agree">
                      I would like to add Refund Guarantee to my order for £3.
                    </label>
                  </div>

                  <div class="radio">
                    <input
                      type="radio"
                      name="refund"
                      id="refund-decline"
                      tabindex="0"
                      :value="false"
                      v-model="refund"
                    />
                    <label for="refund-decline">
                      I do not want to add Refund Guarantee to my order.
                    </label>
                  </div>

                  <span class="error" v-if="!$v.refund.required">
                    Please select your Refund guarantee option
                  </span>
                </div>
              </div>

              <div class="row">
                <h4>
                  <span v-if="shoppingCart.product.id !== 3">Cart total</span>
                  <span v-else>Total you will receive</span>
                  {{ shoppingCart.total | price }}
                </h4>
              </div>
              <span class="spendMessage" if="spendRequiementMessage != ''">{{
                spendRequiementMessage
              }}</span>
              <div class="row">
                <Spinner
                  name="circle"
                  color="var(--color-links)"
                  v-if="orderLoading"
                />
                <input
                  type="submit"
                  class="button button--primary"
                  value="Proceed to checkout"
                  v-if="!orderLoading"
                  :disabled="checkMinMaxSpendRequirement"
                />
              </div>
            </form>

            <form
              v-else
              @submit.prevent="onSubmit"
              autocomplete="off"
              novalidate
            >
              <div class="row">
                <h4>
                  <span v-if="shoppingCart.product.id !== 3">Cart total</span>
                  <span v-else>Total you will receive</span>
                  {{ shoppingCart.total | price }}
                </h4>
              </div>
              <span
                class="spendMessage"
                v-if="shoppingCart.total > 0 && spendRequiementMessage != ''"
                >{{ spendRequiementMessage }}</span
              >
              <div class="row">
                <Spinner
                  name="circle"
                  color="var(--color-links)"
                  v-if="orderLoading"
                />
                <input
                  type="submit"
                  class="button button--primary"
                  value="Proceed to checkout"
                  v-if="!orderLoading"
                  :disabled="checkMinMaxSpendRequirement"
                />
              </div>
            </form>
          </div>
        </div>

        <!-- <div
        v-if="shoppingCart.product.id === 2"
        class="checkout--branch col-3"
      ></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import { showError } from '@vue/services/toast.service'
import { beginCheckout } from '@vue/services/api/checkout.api'
import { saveToken } from '@vue/services/jwt.service'
import { hasBearerToken } from '@vue/services/jwt.service'
import { required } from 'vuelidate/lib/validators'
import { getParameterByName } from '@vue/services/calculator.service'
import { createCart,recreatewebcart } from '@vue/services/api/cart.api'
import { SITE_ID,SITE_NAME } from '@vue/common/constants/config'

import {
  COMMERCE_SHOW_BUYBACK,
  COMMERCE_SHOW_REFUND,
} from '@vue/common/constants/config'

import Spinner from 'vue-spinkit'
import ReviewItem from './components/ReviewItem'
import ReviewItemNew from './components/ReviewItemNew'
import CurrencySelect from '@vue/common/components/form/CurrencySelect'

export default {
  name: 'ViewCart',
  components: {
    Spinner,
    ReviewItem,
    ReviewItemNew,
    CurrencySelect,
  },
  data() {
    return {
      cartEmpty: true,
      cartLoading: true,
      orderLoading: false,
      selected: [],
      buyback: null,
      refund: null,
      spendRequiementMessage: '',
      COMMERCE_SHOW_BUYBACK,
      COMMERCE_SHOW_REFUND,
      SITE_ID,
      SITE_NAME,
      IsPayPoint:false,
    }
  },
  computed: {
    ...mapState({
      shoppingCart: (state) => state.cart.shoppingCart,
      siteProducts: (state) => state.product.siteProducts,
      allCurrencies: (state) => state.currency.currencies,
    }),
    checkMinMaxSpendRequirement() {
      let sp = this.siteProducts.find(
        (x) =>
          x.siteId === SITE_ID && x.productId === this.shoppingCart.product.id
      )
      if (this.shoppingCart.total > 0 && sp) {
        if (this.shoppingCart.total < sp.minSpend) {
          this.spendRequiementMessage = 
            'Minimum spend of ' + sp.minSpend + ' ' + this.shoppingCart.currency.code + ' has not been met'
        } else if (this.shoppingCart.total > sp.maxSpend) {
          this.spendRequiementMessage =
            'Maximum spend of ' + sp.maxSpend + ' ' + this.shoppingCart.currency.code + ' has been exceeded'
        } else {
          this.spendRequiementMessage = ''
        }
      } else {
        this.spendRequiementMessage = ''
      }
      return sp
        ? this.shoppingCart.total<=0 ||
          this.shoppingCart.total < sp.minSpend ||
            this.shoppingCart.total > sp.maxSpend
        : false
    },
    filteredCurrencies() {
      const alreadyInCart = (this.shoppingCart?.lines || []).map(l => l.currency.code);
      return  this.allCurrencies.filter((c) => !alreadyInCart.includes(c.code));
    }
  },
  methods: {
    ...mapMutations({
      updateStep: 'checkout/updateStep',
      loadingCart: 'cart/loadingCart',
    }),
    ...mapActions({
      getCurrencies: 'currency/getCurrencies',
      getSiteProducts:'product/getSiteProducts',
      getCart: 'cart/getCart',
      getMatrix: 'calculator/getMatrix',
      getDefaultStore: 'store/getDefaultStore',
      isBuyBack: 'cart/isBuyBack',
      isRefund: 'cart/isRefund',
      addLine: 'cart/addLine',
      recreatewebcart: 'cart/recreatewebcart',
    }),
    initCart() {
      Promise.all([
        this.getMatrix(),
        this.getDefaultStore(),
        this.getCurrencies(),
        this.getSiteProducts()
      ]).then(() => {
        if (hasBearerToken()) {
          this.getCart()

          if (!COMMERCE_SHOW_BUYBACK) {
            this.buyback = false
            this.isBuyBack(false)
          }

          if (!COMMERCE_SHOW_REFUND) {
            this.refund = false
            this.isRefund(false)
          }

        } else {
          this.cartLoading = false
          this.cartEmpty = true
        }
      })
    },
    onSubmit() {
      this.$v.$touch()

      if (this.$v.$pending || this.$v.$error) {
        return
      }

      this.orderLoading = true

      beginCheckout()
        .then(({ data }) => {
          saveToken(data.token)
          window.location = window.checkoutWorkflow.next
        })
        .catch(() => {
          this.orderLoading = false
        })
    },
    selectCurrency(currency) {
      // Check if the currency is already selected
      if (this.selected.find((selected) => selected.code === currency.code)) {
        return
      }

      this.selected.push(currency)
    },
    removeCurrency(code) {
      this.selected = this.selected.filter((selected) => selected.code !== code)
    },
  },
  validations: {
    buyback: {
      required,
    },
    refund: {
      required,
    },
  },
  watch: {
    buyback(value) {
      if (value !== null) {
        this.isBuyBack(value)
      }
    },
    refund(value) {
      if (value !== null) {
        this.isRefund(value)
      }
    },
    shoppingCart: {
      handler(newCart, oldCart) {
        if (newCart.product.id === 3) {
          this.buyback = false
          this.refund = false
        }
        // if the new cart does not equal the old cart show loading
        if (newCart === null && newCart !== oldCart) {
          this.cartLoading = true
          return
        }

        // if the shopping cart has a buyback set then set buyback
        if (newCart !== null && newCart.buyBackGuarantee) {
          this.buyback = newCart.buyBackGuarantee
        }

        // if the shopping cart is empty, show the empty cart message
        if (newCart.total === 0 && newCart.lines.length > 0) {
          this.cartEmpty = true
          this.cartLoading = false
        } else {
          this.cartEmpty = false
          this.cartLoading = false
        }

        // if the shopping cart total is less than 150, disable the buyback option
        if (newCart.total < 150) {
          this.buyback = false
        }

        if (oldCart === null) {
          return
        }

        // if the old shopping cart total is less than 150 (disabled buyback) and the new cart is greater than 150 (enabled buyback)
        if (oldCart.total < 150 && newCart.total > 150) {
          this.buyback =
            window.commerceSettings.showBuybackGuarantee === false
              ? false
              : null
        }

        // now we've done everything, cart loading is false again
        this.cartLoading = false
      },
      deep: true,
    },
  },
    mounted() {
    this.updateStep('review'), (this.cartLoading = true)
    const queryCurrency = getParameterByName('currency')
    const queryAmount = getParameterByName('amount')
    const queryProduct = getParameterByName('product_id')
    const queryStore = getParameterByName('store_id') || 1
    const id = getParameterByName('id')

    this.IsPayPoint = (this.SITE_NAME == 'PAYPOINT')

    if (queryCurrency && queryAmount && queryProduct && queryStore) {
      const affiliateCode = sessionStorage.getItem('affiliateCode')
        ? sessionStorage.getItem('affiliateCode')
        : null

      createCart({
        siteId: SITE_ID,
        storeId: parseInt(queryStore),
        productId: parseInt(queryProduct),
        affiliateCode,
      }).then(({ data }) => {
        saveToken(data.token)
        this.loadingCart(false)
        this.addLine({
          currencyCode: queryCurrency,
          qty: parseInt(queryAmount),
        }).then(() => {
          this.initCart()
        })
      })
    }else if(id != null) {
      recreatewebcart(id).then(({ data }) => {
      if(data.token != ""){
      saveToken(data.token) 
      this.initCart()
      } else{         
        showError('Your Cart is Expired.')
        window.location = '/'
      }
    })
    }
    else {
      this.initCart()
      }
  },
}
</script>
