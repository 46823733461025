<template>
  <div>
    <div
      class="amount-select"
      v-if="selectedCurrency"
      :class="{ 'visibility-hidden': !quote }"
    >
      <div class="label">
        <div class="row">
          <span>{{
            content.amountSelectLabel || '2. Select how much you would like'
          }}</span>
          <Tooltip :content="content.amountTooltip" />
        </div>
        <div class="row"></div>
      </div>
      <div class="inputs">
        <div class="input" tabindex="-1">
          <small class="currency-code">£</small>
          <input
            maxlength="4"
            min="0"
            type="number"
            @keyup="onUpdateSource"
            :value="desiredSourceQuantity"
            tabindex="0"
          />
        </div>
        <div class="separator">
          <span>=</span>
        </div>
        <div class="input" tabindex="-1">
          <small class="currency-code" v-html="selectedCurrency.symbol"></small>
          <input
            type="number"
            min="0"
            @keyup="onUpdateTarget"
            :value="desiredTargetQuantity"
            tabindex="0"
          />
        </div>
      </div>
    </div>

  <div class="amount-result" v-if="selectedCurrency">
    <div class="flex">
      <div class="item">
        <span>You'll pay {{ customerWillPay | price }}</span>
      </div>
      <div class="item">
        <span> You'll get {{ customerWillGet | price(selectedCurrency.symbol) }} </span>
      </div>
    </div>
  </div>

  <TieredMessage :onBoostClick="boost"/>

</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { nextMultiple } from '@vue/services/currency.service'
  import { DEFAULT_GBP, GBP_MAX_VALUE } from '@vue/common/constants/config'
import { showError } from '@vue/services/toast.service'
import BigNumber from 'bignumber.js'
import Tooltip from '@vue/common/components/Tooltip'
import TieredMessage from './tiered-message'

export default {
  name: 'AmountSelectBuy',
  components: {
    Tooltip,
    TieredMessage,
  },
  data() {
    return {
      debounceTimer: null,
      lastHashCode: null,
      isInitial: true,
      DEFAULT_GBP,
      GBP_MAX_VALUE, 
      desiredSourceQuantity: 0,
      desiredTargetQuantity: 0
    }
  },
  computed: {
    ...mapGetters({
      rate: 'calculator/rate',
      rateForSourceQty: 'calculator/rateForSourceQty',
      rateForTargetQty: 'calculator/rateForTargetQty',
      nextTier: 'calculator/nextTier',
      calculatorPrice: 'calculator/price',
    }),
    ...mapState({
      content: (state) => state.calculator.content,
      quote: (state) => state.calculator.quote,
      selectedCurrency: (state) => state.calculator.selectedCurrency,
      quantity: (s) => s.calculator.quantity
    }),
    customerWillGet() {
      if (!this.selectedCurrency || !this.quantity) {
        return 0
      }
      // account for bank note values, e.g. we don't sell 3 EUR
      return nextMultiple(
        this.selectedCurrency.minimumDenomination,
        this.quantity
      )
    },    
    customerWillPay() {
      return BigNumber(this.customerWillGet)
        .times(BigNumber(this.calculatorPrice))
        .dp(2, BigNumber.ROUND_HALF_EVEN)
        .toNumber();
    }
  },
  watch: {
    'quote': {
      handler(value) {
        if(value.rate == null)  {
          return
        }

        if(this.lastHashCode == value.hashCode) {
          return; // ignore irrelevant changes to rate.
        }

        this.lastHashCode = value.hashCode;

        if(this.isInitial) {
          this.isInitial = false;
          var params = new URLSearchParams(window.location.search);
          let amount = params.get('amount')
          const native = params.get('native') && params.get('native').toLowerCase() === "true"

          if(amount && native) {
            this.updateTarget(Math.floor(Number(amount) || 0));
            return;
          }
          if(amount) {
            this.updateSource(Math.floor(Number(amount) || 0));
            return;
          }
          this.updateSource(DEFAULT_GBP);
        } else {
          this.updateSource(this.desiredSourceQuantity);
        }
      },
    }
  },
  methods: {
    ...mapActions({
      setQuantity: 'calculator/setQuantity',
    }),
    onUpdateSource(event){
      if(event.key == 'Tab') {
        return;
      }
      this.resetTimer();

      this.debounceTimer = setTimeout(() => {
        this.resetTimer();

        const qty = Number(event.target.value) || 0;

        if (qty && qty > GBP_MAX_VALUE) {
          showError('Maximum order value is £' + GBP_MAX_VALUE.toLocaleString('en-US')) // shouldn't be hardcoded...
          this.updateSource(GBP_MAX_VALUE);
        } else {
          this.updateSource(qty);
        }
      }, 500)
    },
    onUpdateTarget(event){
      if(event.key == 'Tab') {
        return;
      }
      this.resetTimer();

      this.debounceTimer = setTimeout(() => {
        this.resetTimer();
        this.updateTarget(Number(event.target.value) || 0);
      }, 500)
    },
    canUpdate() {
      // This component shouldn't ever receive rates for other products, but right now it does so handle it until refactor.
      return this.quote.rate && this.selectedProduct?.id != 3;
    },
    // FC -> GBP
    updateTarget(qty) {
      if(!this.canUpdate()){
        this.reset();
        return;
      }

      const target = BigNumber(qty) // FC
        .dp(2, BigNumber.ROUND_HALF_EVEN)
        .toNumber();

      const source = BigNumber(qty)
          .div(this.rateForTargetQty(qty,  this.selectedCurrency.minimumDenomination))
          .dp(2, BigNumber.ROUND_HALF_EVEN)
          .toNumber()

      this.desiredSourceQuantity = source;
      this.desiredTargetQuantity = target; 

      this.updateQuantity(target)
    },
    // GBP -> FC
    updateSource(qty) {
      if(!this.canUpdate()){
        this.reset();
        return;
      }
      const source = BigNumber(qty) // GBP
        .dp(2, BigNumber.ROUND_HALF_EVEN)
        .toNumber();

      const target = BigNumber(qty) // FC
        .times(this.rateForSourceQty(qty))
        .dp(2, BigNumber.ROUND_HALF_EVEN)
        .toNumber();

      this.desiredSourceQuantity = source
      this.desiredTargetQuantity = target;

      this.updateQuantity(target);
    },
    updateQuantity(qty) {
      qty = Math.floor(qty) // lose decimals
      qty = nextMultiple(this.selectedCurrency.minimumDenomination, qty)

      this.setQuantity(qty)
    },
    boost(qty) {
      this.desiredTargetQuantity = BigNumber(this.nextTier.amount).toNumber(); // FC

      this.desiredSourceQuantity = BigNumber(this.nextTier.amount) // GBP
        .div(this.nextTier.rate)
        .dp(2, BigNumber.ROUND_HALF_EVEN)
        .toNumber();

      this.setQuantity(qty);
    },
    reset() {
      this.desiredSourceQuantity = 0;
      this.desiredTargetQuantity = 0;

      this.setQuantity(0);
    },
    resetTimer(){
      clearTimeout(this.debounceTimer);
      this.debounceTimer = null;
    }
  },
}
</script>
