/**
 * ProductType
 **/
export const ProductTypes = {
  /** Delivery of currency to a customer's home **/
  HOME_DELIVERY: 1,
  /** Customer collects currency from a branch **/
  CLICK_AND_COLLECT: 2,
  /** Company buys currency from a customer **/
  SELL_BACK: 3
};
