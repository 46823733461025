var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "payment", class: { "payment-bordered": _vm.showBorder } },
      [
        _c("div", [
          _c(
            "button",
            {
              staticClass: "button button--checkout",
              attrs: { disabled: _vm.disabled },
              on: { click: _vm.redirectToCheckout },
            },
            [_vm._v("\n        Review & checkout\n      ")]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }